export enum SocialMediaTypeEnum {
    DAILYMOTION = 'dailymotion',
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    LINKEDIN = 'linkedin',
    STRAVA = 'strava',
    TIKTOK = 'tiktok',
    TWITTER = 'twitter',
    WECHAT = 'wechat',
    X = 'x',
    YOUTUBE = 'youtube',
}
