import { IconEnum } from "@utmb/types/enum/IconEnum";
import { SocialMediaTypeEnum } from "@utmb/types/enum/SocialMediaTypeEnum";

export const getIconFromSocialMediaType = (sm: SocialMediaTypeEnum): IconEnum | null => {
  switch(sm) {
    case SocialMediaTypeEnum.DAILYMOTION: return IconEnum.SOCIAL_DAILYMOTION;
    case SocialMediaTypeEnum.FACEBOOK: return IconEnum.SOCIAL_FACEBOOK;
    case SocialMediaTypeEnum.INSTAGRAM: return IconEnum.SOCIAL_INSTAGRAM;
    case SocialMediaTypeEnum.LINKEDIN: return IconEnum.SOCIAL_LINKEDIN;
    case SocialMediaTypeEnum.STRAVA: return IconEnum.SOCIAL_STRAVA;
    case SocialMediaTypeEnum.TIKTOK: return IconEnum.SOCIAL_TIKTOK;
    case SocialMediaTypeEnum.TWITTER: return IconEnum.SOCIAL_TWITTER;
    case SocialMediaTypeEnum.WECHAT: return IconEnum.SOCIAL_WECHAT;
    case SocialMediaTypeEnum.X: return IconEnum.SOCIAL_X;
    case SocialMediaTypeEnum.YOUTUBE: return IconEnum.SOCIAL_YOUTUBE;
    default: return null;
  }
};
