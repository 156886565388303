import { ReactElement } from 'react';
import { default as ImageNext } from 'next/image';
import { Col, ColToRow } from 'src/design-system/box/Box';
import styles from './footer.module.scss';
import { Spacer } from 'src/design-system/spacer/Spacer';
import { Font } from 'src/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from 'src/design-system/link/link';
import { v4 as uuidv4 } from 'uuid';
import { GlobalEventView, GlobalFooterMenuView, GlobalFooterView, LinkView } from '../../types';
import { ColorTypesEnum } from '@utmb/types/colors';
import { SocialMedia } from '../layout/social-media/SocialMedia';

export interface FooterProps extends GlobalFooterView {
    event: GlobalEventView;
}

export const Footer = (props: FooterProps): ReactElement => {
    const { copyrightText, bottomNavigation, mainNavigation, socialMediaNavigation, event } = props;
    const mainRowClasses = ['main-start', 'cross-start', styles.footer_row].filter(Boolean).join(' ');
    const bottomRowClasses = ['main-start', 'cross-start', styles.footer_bottom_row].filter(Boolean).join(' ');

    return (
        <Col className={styles.footer_container}>
            <div className={styles.separator} />
            <div className={styles.footer_W_logo}>
                <ImageNext layout="fill" src="/svg/M_outline_logo.svg" role="presentation" />
            </div>

            <Col className="container">
                {/*
                //  Main Row --------------------------------------------------------- Navigation
                */}
                <ColToRow className={mainRowClasses}>
                    <div className={styles.footer_logo_wrapper}>
                        <Image objectFit="contain" image={event.siteLogoDark ?? event.siteLogo} />
                    </div>

                    <ColToRow className={styles.footer_nav}>
                        {mainNavigation?.map((mainNavItem: GlobalFooterMenuView) => (
                            <Col className={styles.footer_nav_column} key={uuidv4()}>
                                <Font.OxaniumRegular mobile="14" className="font-uppercase">
                                    {mainNavItem.label}
                                </Font.OxaniumRegular>
                                <Spacer.size10 />

                                {mainNavItem.children?.map((mainNavLink: LinkView) => (
                                    <Link
                                        className={styles.footer_nav_link}
                                        href={mainNavLink.slug}
                                        followClassName={`${mainNavLink.followClass}-ga`}
                                        key={uuidv4()}
                                    >
                                        <Font.FuturaHeavy mobile="16">{mainNavLink.label}</Font.FuturaHeavy>
                                    </Link>
                                ))}
                            </Col>
                        ))}

                        <Col className={styles.footer_nav_column}>
                            <Font.OxaniumRegular mobile="14" className="font-uppercase">
                                {socialMediaNavigation.label}
                            </Font.OxaniumRegular>
                            <Spacer.size10 />
                            <SocialMedia className={styles.footer_social_media} colorTheme={ColorTypesEnum.WHITE} />
                        </Col>
                    </ColToRow>
                </ColToRow>
                {/*
                //  Divider ---------------------------------------------------------
                */}
                <Spacer custom="60" className={styles.footer_custom_spacer} />
                <Spacer custom="60" className={styles.footer_custom_spacer} />
                {/*
                //  Bottom Row --------------------------------------------------------- Terms / Privacy Policy / Copyright
                */}
                <ColToRow className={bottomRowClasses}>
                    {bottomNavigation.map((bottomNavItem: LinkView) => (
                        <div className={styles.footer_nav_column} key={uuidv4()}>
                            <Link href={bottomNavItem.slug} followClassName={`${bottomNavItem.followClass}-ga`}>
                                <Font.OxaniumRegular mobile="12">{bottomNavItem.label}</Font.OxaniumRegular>
                            </Link>
                        </div>
                    ))}

                    <Col className={styles.footer_nav_column_copyright}>
                        <Font.OxaniumRegular mobile="12">{copyrightText}</Font.OxaniumRegular>
                    </Col>
                </ColToRow>
            </Col>
        </Col>
    );
};
