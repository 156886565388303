import { ReactElement } from 'react';
// Features
import { Col, Row } from '../../../design-system/box/Box';
// Styles
import styles from './social-media.module.scss';
import { useGlobal } from '@utmb/contexts/GlobalContext';
import { GlobalFooterSocialMediaView } from '@utmb/types/index';
import { Link } from '@utmb/design-system/link/link';
import { Icon } from '@utmb/design-system/icons/Icon';
import { getIconFromSocialMediaType } from '@utmb/design-system/icons/SocialMedia.utils';
import { getColorStyle } from '@utmb/utils/colors';
import { ColorTypesEnum } from '@utmb/types/colors';

interface Props {
    /* extra styles */
    className?: string;
    /* variants */
    orientation?: 'horizontal' | 'vertical';
    /* color theme */
    colorTheme?: ColorTypesEnum;
}

export const SocialMedia = (props: Props): ReactElement | null => {
    const { className, orientation = 'horizontal', colorTheme = ColorTypesEnum.WS_PRIMARY } = props;
    const Tag = orientation === 'horizontal' ? Row : Col;

    const color = getColorStyle(colorTheme);

    const {
        global: {
            footer: { socialMediaNavigation },
        },
    } = useGlobal();

    return (
        <Tag className={`${styles.container} ${className}`}>
            {socialMediaNavigation?.children?.map((social: GlobalFooterSocialMediaView, index: number) => {
                if (social.url && social.name) {
                    const icon = getIconFromSocialMediaType(social.name);
                    if (icon)
                        return (
                            <Link href={social.url} className={styles.social_item} key={`social-${social.id}-${index}`}>
                                <Icon variant={icon} color={color} />
                            </Link>
                        );
                    else return <>{social.name}</>;
                }
            })}
        </Tag>
    );
};

SocialMedia.Vertical = (props: Props) => <SocialMedia {...props} orientation="vertical" />;
